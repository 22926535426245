import React, { Fragment, useEffect, useContext } from "react"

import { NewsContext } from "../../contexts/newsContext"

import Loader from '../../components/loader';
import AdminLayout from "../../layouts/admin"
import SEO from "../../components/seo"

import { Empty } from 'antd';

const AdminLinksPage = ({ location }) => {
  const { newsItem, newsItemError, newsItemLoading, getPageNewsItem } = useContext(NewsContext);
	useEffect(() => {
		getPageNewsItem('adminLinks');
  }, []);
  
  return (
    <AdminLayout
      path={location.pathname}
      breadcrumbs={[{ link: "/admin/links", title: "Links" }]}
    >
      <SEO title="Admin links" />

      { newsItemLoading ?
        <Loader />
      :
        <Fragment>
          { newsItemError || !newsItem ?
            <Empty />
          :
            <Fragment>
              <h1>{newsItem.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: newsItem.text }} />
            </Fragment>
          }

        </Fragment>

      }
    </AdminLayout>
  )
}

export default AdminLinksPage
